<template>
  <div>
    <touch-optimized-button
      @click="$refs.modal.open()"
    >
      <span v-if="getSelectedStatus" class="font-bold">{{ getSelectedStatus.label }}</span>
      <span v-else>Status</span>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex justify-center">
        <touch-optimized-modal-button
          class="mb-24 w-max"
          @click="onClickStatus(null)"
        >
          Tous
        </touch-optimized-modal-button>
      </div>
      <div class="flex flex-wrap gap-8 clear-both">
        <touch-optimized-modal-button
          v-for="status in possibleStatuses"
          :key="status.value"
          :border-class="`border-${status.color}`"
          :text-class="`text-${status.color}`"
          @click="onClickStatus(status)"
        >
          {{ status.label }}
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "OrderFulfilmentListStatusButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    possibleStatuses: [
      {label: 'Confirmé', value: 'confirmed', color: 'yellow-300'},
      {label: 'En préparation', value: 'inFulfilment', color: 'yellow-600'},
      {label: 'Preparé', value: 'fulfilmentFinalized', color: 'luxcaddy-300'},
    ]
  }),
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedStatus'
    ]),
    onClickStatus(status) {
      this.setSelectedStatus(status);
      this.$refs.modal.close();
    },
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedStatus'
    ]),
  },
}
</script>

